/*
Auto-generated by: https://github.com/pmndrs/	
*/

import { PerspectiveCamera, useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { MathUtils } from 'three';

import { usePioAnimation } from './animation';

const inv = shouldInvert => (shouldInvert ? -1 : 1);

export default function Model({ ...props }) {
  const group = useRef(null);
  const { nodes, materials, animations } = useGLTF(
    '/Pio_Animation-transformed.glb'
  );

  const { isLastStep } = usePioAnimation(animations, group);

  const invertMouseX = false;
  const invertMouseY = true;
  const angleDeg = 10;
  const yRotDamp = 0.8;
  const xRotDamp = 0.5;
  const lerp = 0.1;
  const smoothMousePos = false;
  const ref = useRef();
  let timer = 0;

  const mouseMoveMultiplier = useRef(1.0);

  const getSmoothMousePos = useCallback(
    pos =>
      smoothMousePos
        ? Math.sign(pos) * THREE.MathUtils.smoothstep(Math.abs(pos), 0, 1)
        : pos,
    [smoothMousePos]
  );

  const [forward] = useState(() => new THREE.Vector3());
  const [up] = useState(() => new THREE.Vector3());
  const [right] = useState(() => new THREE.Vector3());

  const { camera } = useThree();
  camera.getWorldDirection(forward);

  useEffect(() => {
    if (ref.current) {
      up.copy(ref.current.up).applyMatrix4(ref.current.matrixWorld).normalize();
      right.crossVectors(forward, up).normalize();
    }
    // eslint-disable-next-line
  }, [ref.current, forward, right, up]);

  const [qX] = useState(() => new THREE.Quaternion());
  const [qY] = useState(() => new THREE.Quaternion());

  useFrame(({ pointer }) => {
    const angle = THREE.MathUtils.degToRad(angleDeg);
    const rotationX = THREE.MathUtils.lerp(
      ref.current.rotation.x,
      inv(invertMouseY) *
        getSmoothMousePos(pointer.y) *
        yRotDamp *
        angle *
        mouseMoveMultiplier.current,
      lerp
    );

    qX.setFromAxisAngle(right, rotationX);

    const rotationY = THREE.MathUtils.lerp(
      ref.current.rotation.y,
      inv(invertMouseX) *
        getSmoothMousePos(pointer.x) *
        xRotDamp *
        angle *
        mouseMoveMultiplier.current,
      lerp
    );

    qY.setFromAxisAngle(up, rotationY);
    ref.current.quaternion.multiplyQuaternions(qX, qY);

    if (isLastStep) {
      if (timer < 1) {
        timer += 0.005;
      }
      mouseMoveMultiplier.current = MathUtils.lerp(1.0, 0.0, timer);
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group
          name="Teapot001"
          position={[-0.03, -0.39, -0.21]}
          rotation={[1.18, -0.36, 0.71]}
          userData={{ name: 'Teapot001' }}
        >
          <PerspectiveCamera
            name="Camera003_1"
            makeDefault
            far={10000}
            fov={29.2}
            position={[0, 3.65, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'Camera003' }}
          />
        </group>
        <group
          ref={ref}
          name="TILTER"
          position={[-0.04, -0.66, -0.21]}
          userData={{ name: 'TILTER' }}
        >
          <mesh
            name="Sphere023"
            geometry={nodes.Sphere023.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.26, 0.05, 0.24]}
            userData={{ name: 'Sphere.023' }}
          />
          <mesh
            name="Cylinder034"
            geometry={nodes.Cylinder034.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.01, 0.3, -0.25]}
            userData={{ name: 'Cylinder.034' }}
          />
          <mesh
            name="Cylinder010"
            geometry={nodes.Cylinder010.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.26, 0.05, -0.26]}
            userData={{ name: 'Cylinder.010' }}
          />
          <mesh
            name="Sphere028"
            geometry={nodes.Sphere028.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.25, 0.3, 0.24]}
            userData={{ name: 'Sphere.028' }}
          />
          <mesh
            name="Cylinder003"
            geometry={nodes.Cylinder003.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.01, 0.06, -0.25]}
            rotation={[Math.PI, 0, Math.PI]}
            userData={{ name: 'Cylinder.003' }}
          />
          <mesh
            name="Cube014"
            geometry={nodes.Cube014.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.01, 0.3, 0.24]}
            userData={{ name: 'Cube.014' }}
          />
          <mesh
            name="Cylinder009"
            geometry={nodes.Cylinder009.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.26, 0.55, -0.26]}
            userData={{ name: 'Cylinder.009' }}
          />
          <mesh
            name="Cube027"
            geometry={nodes.Cube027.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.26, 0.3, -0.26]}
            userData={{ name: 'Cube.027' }}
          />
          <mesh
            name="Cube006"
            geometry={nodes.Cube006.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.03, 0.05, 0.24]}
            userData={{ name: 'Cube.006' }}
          />
          <mesh
            name="Cylinder026"
            geometry={nodes.Cylinder026.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.12, 0.3, -0.01]}
            userData={{ name: 'Cylinder.026' }}
          />
          <mesh
            name="Cylinder042"
            geometry={nodes.Cylinder042.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.13, -0.2, 0.24]}
            userData={{ name: 'Cylinder.042' }}
          />
          <mesh
            name="Cylinder001"
            geometry={nodes.Cylinder001.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.31, -0.2, -0.19]}
            userData={{ name: 'Cylinder001' }}
          />
          <mesh
            name="cube09"
            geometry={nodes.cube09.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, 0.05, -0.25]}
            userData={{ name: 'cube.09' }}
          />
          <mesh
            name="Cylinder023"
            geometry={nodes.Cylinder023.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, 0.05, 0.24]}
            userData={{ name: 'Cylinder.023' }}
          />
          <mesh
            name="Cube017"
            geometry={nodes.Cube017.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, -0.2, 0.25]}
            userData={{ name: 'Cube.017' }}
          />
          <mesh
            name="Cube005"
            geometry={nodes.Cube005.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.26, -0.2, -0.01]}
            userData={{ name: 'Cube.005' }}
          />
          <mesh
            name="Cube007"
            geometry={nodes.Cube007.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, 0.3, -0.25]}
            userData={{ name: 'Cube.007' }}
          />
          <mesh
            name="Cylinder033"
            geometry={nodes.Cylinder033.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, 0.3, 0.24]}
            userData={{ name: 'Cylinder.033' }}
          />
          <mesh
            name="GRID_B_001"
            geometry={nodes.GRID_B_001.geometry}
            material={materials['Material.007']}
            position={[-0.37, -0.19, -0.38]}
            scale={[0.67, 0.56, 0.66]}
            userData={{ name: 'GRID_B_001' }}
          />
          <mesh
            name="Cylinder044"
            geometry={nodes.Cylinder044.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[-0.24, 0.04, 0]}
            userData={{ name: 'Cylinder.044' }}
          />
          <mesh
            name="Cylinder043"
            geometry={nodes.Cylinder043.geometry}
            receiveShadow
            castShadow
            material={materials['Material #31']}
            position={[0.01, -0.19, -0.13]}
            rotation={[0, 1.57, 0]}
            userData={{ name: 'Cylinder.043' }}
          />
          <mesh
            name="2D_Grid"
            geometry={nodes['2D_Grid'].geometry}
            material={materials['Material #30']}
            morphTargetDictionary={nodes['2D_Grid'].morphTargetDictionary}
            morphTargetInfluences={nodes['2D_Grid'].morphTargetInfluences}
            position={[0.01, 0.05, 0]}
            scale={0.02}
            userData={{
              targetNames: ['2D_Grid.morpher(1).target(0)'],
              name: '2D_Grid'
            }}
          />
          {/* Floor for shadows */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -0.2, 0]}
            receiveShadow
          >
            <planeGeometry attach="geometry" args={[100, 100]} />
            <shadowMaterial attach="material" transparent opacity={0.05} />
          </mesh>
        </group>
      </group>
    </group>
  );
}
