import cx from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';

import Text from '../Text';
import styles from './styles.module.scss';

interface BaseTab {
  value: string | boolean;
}

interface TextTab extends BaseTab {
  name: string;
}

interface ComponentTab extends BaseTab {
  component: ReactElement; // Typically an Icon
}

export type Tab = TextTab | ComponentTab;

export interface SlidingTabsProps {
  options: [Tab, Tab];
  initialOption?: Tab;
  handleClick: (currentState: Tab) => void;
  variant?: 'light' | 'dark';
}

const SlidingTabs = ({
  options,
  initialOption,
  handleClick,
  variant = 'dark'
}: SlidingTabsProps) => {
  const [optionA, optionB] = options;
  const [activeOption, setActiveOption] = useState(initialOption || optionA);

  // set the tabs/buttons back to the initalOption when the grid is reset to default
  useEffect(() => {
    if (initialOption) {
      setActiveOption(initialOption);
    }
  }, [initialOption]);

  return (
    <div
      className={cx(styles.tabs, {
        [styles.light]: variant === 'light'
      })}
    >
      <input
        type="radio"
        id={`radio-${optionA.value}`}
        name="tabs"
        className={styles.hidden}
        onClick={() => {
          setActiveOption(optionA);
          handleClick(optionA);
        }}
        readOnly
        checked={isActiveOption(activeOption, optionA)}
      />
      <label
        className={cx(styles.tab, styles.optionA, {
          [styles.active]: isActiveOption(activeOption, optionA)
        })}
        htmlFor={`radio-${optionA.value}`}
      >
        {'name' in optionA ? (
          <Text variant="b2">{optionA.name}</Text>
        ) : (
          React.cloneElement(optionA.component, {
            fill: isActiveOption(activeOption, optionA)
              ? 'primary'
              : 'secondary',
            className: styles.icon
          })
        )}
      </label>
      <input
        type="radio"
        id={`radio-${optionB.value}`}
        name="tabs"
        className={styles.hidden}
        onClick={() => {
          setActiveOption(optionB);
          handleClick(optionB);
        }}
        readOnly
        checked={isActiveOption(activeOption, optionB)}
      />
      <label
        className={cx(styles.tab, styles.optionB, {
          [styles.active]: isActiveOption(activeOption, optionB)
        })}
        htmlFor={`radio-${optionB.value}`}
      >
        {'name' in optionB ? (
          <Text variant="b2">{optionB.name}</Text>
        ) : (
          React.cloneElement(optionB.component, {
            fill: isActiveOption(activeOption, optionB)
              ? 'primary'
              : 'secondary',
            className: styles.icon
          })
        )}
      </label>
      <span className={styles.glider} />
    </div>
  );
};

export const isActiveOption = (activeOption: Tab, testOption: Tab) =>
  activeOption.value === testOption.value;

export default SlidingTabs;
