import cx from 'classnames';

import styles from './FieldGroup.module.scss';

interface FieldGroupProps {
  className?: string;
  children?: React.ReactNode;
}

const FieldGroup: React.FC<FieldGroupProps> = ({ children, className }) => (
  <div className={cx(styles.group, className)}>{children}</div>
);

export default FieldGroup;
