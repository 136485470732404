import { useAnimations } from '@react-three/drei';
import { useContext, useEffect } from 'react';
import * as THREE from 'three';

import { VideoContext } from './context';
import { TOTAL_ANIMATION_STEPS } from './main';

export const usePioAnimation = (
  animations: THREE.AnimationClip[],
  group: React.MutableRefObject<null>
) => {
  const { actions, names, mixer } = useAnimations(animations, group);
  const index = useContext(VideoContext);

  // const loopAction = ({ action }) => _play(action);

  const _play = (
    action: THREE.AnimationAction | null,
    loop: THREE.AnimationActionLoopStyles = THREE.LoopOnce
  ) => {
    if (!action) return;
    // action.reset();
    action.setEffectiveTimeScale(1);
    action.clampWhenFinished = true;
    action.setLoop(loop, 0);
    action.fadeIn(0.2);
    action.play();
  };

  useEffect(() => {
    const prevAction = index > 0 ? actions[names[index - 1]] : undefined;
    const currentAction = actions[names[index]];

    const playAction = () => {
      prevAction && prevAction.fadeOut(0.2);
      _play(currentAction);
      mixer.removeEventListener('finished', playAction);
    };

    // if first video, at the end loop it again
    if (index === 0) {
      mixer.stopAllAction();
      currentAction?.reset();

      _play(currentAction, THREE.LoopRepeat);
      // mixer.addEventListener('finished', loopAction);
    } else {
      if (prevAction && prevAction.isRunning()) {
        if (index === 1) {
          const remainingTime = prevAction.getClip().duration - prevAction.time;
          prevAction.warp(1, 1.6, remainingTime);

          // if (prevAction.time > prevAction.getClip().duration / 3) {
          //   prevAction.warp(1, 3, 0.2);
          // } else {
          //   prevAction.warp(1, -2, 0.2);
          // }
        }

        mixer.addEventListener('finished', playAction);
      } else {
        playAction();
      }

      // if (prevAction && prevAction.isRunning()) {
      //   if (index === 1) {
      //     prevAction.warp(1, 3, 0.2);
      //   }

      //   mixer.addEventListener('finished', playAction);
      // } else {
      //   playAction();
      // }
    }

    if (index === 0) {
      return () => {
        currentAction?.setLoop(THREE.LoopOnce, 1);
        // mixer.removeEventListener('finished', loopAction);
      };
    }
  }, [index, actions, names, mixer]);

  return { isLastStep: index === TOTAL_ANIMATION_STEPS - 1 };
};
