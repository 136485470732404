import SlidingTabs, { Tab } from '@components/atoms/SlidingTabs';
import Title from '@components/atoms/Title';
import { MediumTitle } from '@components/website/modules/ModuleContent/Components';
import useBuilderFormStore from '@grid/store/useBuilderFormStore';
import { BuilderForm as BuilderFormType } from '@sanity/sanity.types';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

import { DEFAULT_ORDERLINES } from '../../../../constants';
import { UnitType } from '../../../../types';
import { feetToMeters } from '../../../../utils/numberUtils';
import Steps from '../../../atoms/Steps';
import Button from '../../../Button';
import FieldGroup from '../../../Form/FieldGroup';
import { InputField } from '../../../inputs';
import AnimationApp from './animation/main';
import styles from './ModuleBuilderForm.module.scss';

interface BuilderFormValuesFirst {
  width: number;
  height: number;
  length: number;
}

interface BuilderFormValuesSecond {
  numberOrders: number;
  numberOrderLines: string;
}

const unitOptions: [Tab, Tab] = [
  { value: 'ft', name: 'FT' },
  { value: 'm', name: 'M' }
];

const validationSchemaFirst = yup.object().shape({
  width: yup.number().typeError('Should be a number').required(),
  height: yup.number().typeError('Should be a number').required(),
  length: yup.number().typeError('Should be a number').required()
});

const validationSchemaSecond = yup.object().shape({
  numberOrders: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable(),
  numberOrderLines: yup
    .string()
    .matches(/[0-9]+([.,][0-9]+)?/, {
      message: 'should be a number'
    })
    .nullable()
});

const ModuleBuilderForm: React.FC<BuilderFormType> = () => {
  const formStore = useBuilderFormStore();
  const router = useRouter();
  const [step, setStep] = useState(1);

  const normalizeSize = useCallback(
    (value: number) => {
      const isFeet = formStore.data?.unit === 'ft';
      return Math.floor(isFeet ? feetToMeters(value) : value);
    },
    [formStore.data]
  );

  const handleClick = (option: Tab) => {
    formStore.setData({
      unit: option.value as UnitType
    });
  };

  const handleSubmitFirst = (data: BuilderFormValuesFirst) => {
    formStore.setData({
      width: normalizeSize(data.width),
      length: normalizeSize(data.length),
      height: normalizeSize(data.height)
    });
    setStep(2);
  };

  const handleSubmitSecond = ({
    numberOrderLines,
    numberOrders
  }: BuilderFormValuesSecond) => {
    formStore.setData({
      numberOrders: numberOrders,
      numberOrderLines:
        parseFloat(numberOrderLines?.replace(/,/, '.')) || DEFAULT_ORDERLINES
    });
    formStore.setIsSubmitted(true);

    setStep(3);
    setTimeout(() => {
      router.push({
        pathname: '/builder'
      });
    }, 1500);
  };

  return (
    <div className={styles.builderForm}>
      <div className={styles.inner}>
        <div className={classNames([styles.column, styles.leftColumn])}>
          <div className={styles.leftColumnInner}>
            <div className={styles.intro}>
              {step === 1 && <MediumTitle text="Inventory" />}
              {step === 2 && <MediumTitle text="Orders" />}

              {step === 1 && (
                <SlidingTabs
                  initialOption={unitOptions.find(
                    option => option.value === formStore.data.unit
                  )}
                  options={unitOptions}
                  handleClick={handleClick}
                  variant="light"
                />
              )}
              <Title as="h2" variant="h3" className={styles.title}>
                {step === 1 &&
                  'Roughly, what are your current warehouse dimensions?'}
                {step === 2 && 'On average, how many orders do you have?'}
                {step === 3 && 'Loading the builder...'}
              </Title>
            </div>
            {step === 1 && (
              <Formik<BuilderFormValuesFirst>
                initialValues={{
                  width: formStore.data.width ?? 0,
                  height: formStore.data.height ?? 0,
                  length: formStore.data.length ?? 0
                }}
                onSubmit={handleSubmitFirst}
                validationSchema={validationSchemaFirst}
                enableReinitialize
              >
                {({ isSubmitting, isValid }) => (
                  <Form id="builder_form" className={styles.form}>
                    <FieldGroup>
                      <InputField
                        id="width-field"
                        name="width"
                        type="number"
                        label={`Width (in ${
                          formStore.data.unit === 'm' ? 'metres' : 'feet'
                        })`}
                        placeholder="Enter the width"
                      />
                      <InputField
                        id="length-field"
                        name="length"
                        type="number"
                        label={`Length (in ${
                          formStore.data.unit === 'm' ? 'metres' : 'feet'
                        })`}
                        placeholder="Enter the length"
                      />
                      <InputField
                        id="height-field"
                        name="height"
                        type="number"
                        label={`Height (in ${
                          formStore.data.unit === 'm' ? 'metres' : 'feet'
                        })`}
                        placeholder="Enter the height"
                      />
                      <div className={styles.buttons}>
                        <Button
                          type="submit"
                          variant="smallMain"
                          disabled={isSubmitting || !isValid}
                        >
                          Next
                        </Button>
                        <Button
                          type="button"
                          variant="link"
                          onClick={() => setStep(2)}
                        >
                          Skip
                        </Button>
                      </div>
                    </FieldGroup>
                  </Form>
                )}
              </Formik>
            )}
            {(step === 2 || step === 3) && (
              <Formik<BuilderFormValuesSecond>
                initialValues={{
                  numberOrders: formStore.data.numberOrders ?? 0,
                  numberOrderLines:
                    formStore.data.numberOrderLines?.toString() ?? ''
                }}
                onSubmit={handleSubmitSecond}
                validationSchema={validationSchemaSecond}
                enableReinitialize
              >
                {({ isSubmitting, isValid }) => (
                  <Form id="builder_form" className={styles.form}>
                    <FieldGroup>
                      <InputField
                        id="numberOrders-field"
                        name="numberOrders"
                        type="number"
                        label="Orders per day"
                        placeholder="Enter your number of orders"
                      />
                      <InputField
                        id="length-field"
                        name="numberOrderLines"
                        type="text"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        label="Order lines per order"
                        placeholder="Enter your order lines"
                      />
                      <div className={styles.buttons}>
                        <Button
                          type="submit"
                          variant="smallMain"
                          isLoading={isSubmitting}
                          disabled={isSubmitting || !isValid}
                        >
                          Next
                        </Button>
                        <Button
                          type="submit"
                          variant="link"
                          isLoading={isSubmitting}
                        >
                          Skip
                        </Button>
                      </div>
                    </FieldGroup>
                  </Form>
                )}
              </Formik>
            )}
            {step < 3 && (
              <Steps
                numberOfSteps={2}
                activeStep={step}
                onStepClick={newStep => {
                  if (newStep > step) {
                    return;
                  }
                  setStep(newStep);
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.animation}>
            <AnimationApp step={step} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleBuilderForm;
