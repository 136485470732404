import { SoftShadows, useContextBridge } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { Vector3 } from 'three';

import { VideoContext } from './context';
import Model from './model';

// Useful links
// https://docs.pmnd.rs/react-three-fiber/getting-started/introduction
// https://threejs.org/docs/#api/
// https://github.com/pmndrs/drei

// Examples
// https://codesandbox.io/s/reflectorplanes-and-bloom-jflps

// https://github.com/pmndrs/gltfjsx
// > npx gltfjsx Pio_Animation.glb -tk

// Inject soft shadow shader
const shadowProps = {
  // frustum: 3.5,
  // size: 0.005,
  // near: 9.5,
  samples: 8
  //  rings: 11, // Rings (default: 11) must be a int
};

function FillLight() {
  return (
    <directionalLight
      color={'#fefce3'}
      castShadow
      position={[1.5, 5, 4]}
      lookAt={() => new Vector3(0, 0, 0)}
      intensity={0.4}
      shadow-mapSize-width={4096}
      shadow-mapSize-height={4096}
      shadow-camera-far={10}
      shadow-camera-near={0.001}
      shadow-camera-left={-10}
      shadow-camera-right={10}
      shadow-camera-top={10}
      shadow-camera-bottom={-10}
      shadow-bias={-0.001}
    />
  );
}

function FillLightB() {
  return (
    <directionalLight
      position={[-15.5, 600, 0]}
      lookAt={() => new Vector3(0, 0, 0)}
      intensity={0.3}
      color={'#ffffff'}
    />
  );
}

function SceneWrapper() {
  const ContextBridge = useContextBridge(VideoContext);

  return (
    <Canvas
      legacy={true}
      id="builder-form-animation"
      gl={{ antialias: true, preserveDrawingBuffer: true }}
      shadows
      style={{ borderRadius: '24px' }}
    >
      <ContextBridge>
        <Scene />
      </ContextBridge>
    </Canvas>
  );
}

function Scene() {
  SoftShadows(shadowProps);

  return (
    <>
      <color attach="background" args={['#B9B9B5']} />
      <FillLight />
      <FillLightB />

      <ambientLight intensity={0.2} />
      <Suspense fallback={null}>
        <Model />
      </Suspense>
    </>
  );
}

export default SceneWrapper;
