import { BuilderForm } from '@sanity/sanity.types';

import {
  AutostoreCardAndMediaType,
  BigGalleryType,
  CustomerStoriesType,
  LeftColumnType,
  ModuleContentType,
  OneMediaType,
  RightColumnType,
  TwoMediaType
} from '../../../../types';
import { ModuleComponentType } from '../../../../types/module';
import ModuleBuilderForm from '../ModuleBuilderForm';
import {
  AutostoreCardAndMedia,
  BigGallery,
  CustomerStories,
  Editorial,
  LeftDigits,
  LeftMedia,
  List,
  MediumTitle,
  OneMedia,
  Quote,
  RightMedia,
  TwoMedia
} from './Components';
import ModuleContent from './ModuleContent';

// TODO: Reconsider splitting this out from ModuleContent
const SanityModuleContent: ModuleComponentType = ({ data }) => {
  const { leftColumn, rightColumn, bottomComponent, verticalPadding } =
    data as unknown as ModuleContentType;

  const rightColumnIsQuote =
    rightColumn && rightColumn.length > 0 && rightColumn[0]._type == 'quote';

  const bottomComponentIsBigGallery =
    bottomComponent &&
    bottomComponent.length > 0 &&
    bottomComponent[0]._type == 'bigGallery';

  const leftComponent = getLeftColumnComponent(leftColumn);
  const rightComponent = getRightColumnComponent(rightColumn);
  const renderedBottomComponent = (
    <>
      {bottomComponent?.map((c, index: number) => (
        <div key={index}>{getBottomComponent(c)}</div>
      ))}
    </>
  );
  return (
    <ModuleContent
      hasGrayBg={rightColumnIsQuote || bottomComponentIsBigGallery}
      leftColumn={leftComponent}
      rightColumn={rightComponent}
      bottomComponent={renderedBottomComponent}
      verticalPadding={verticalPadding}
    />
  );
};

const getLeftColumnComponent = (leftColumn: LeftColumnType) => {
  if (leftColumn === null || leftColumn.length !== 1) return;

  const object = leftColumn[0];

  switch (object._type) {
    case 'leftDigits':
      return <LeftDigits text={object.text ?? ''} />;
    case 'mediumTitle':
      return <MediumTitle text={object.text ?? ''} />;
    case 'leftMedia':
      return <LeftMedia {...object} />;
    case 'editorial':
      return <Editorial {...object} />;
    default:
      return;
  }
};

const getRightColumnComponent = (rightColumn: RightColumnType) => {
  if (rightColumn === null || rightColumn.length !== 1) return;

  const object = rightColumn[0];

  switch (object._type) {
    case 'editorial':
      return <Editorial {...object} />;
    case 'quote':
      return (
        <Quote
          quotation={object.quotation ?? ''}
          name={object?.name}
          position={object?.position}
          {...object}
        />
      );
    case 'list':
      return <List items={object?.items ?? []} />;
    case 'rightMedia':
      return <RightMedia {...object} />;
    default:
      return;
  }
};

const getBottomComponent = (
  bottomComponent:
    | BuilderForm
    | OneMediaType
    | TwoMediaType
    | CustomerStoriesType
    | BigGalleryType
    | AutostoreCardAndMediaType
) => {
  if (!bottomComponent) return;

  switch (bottomComponent._type) {
    case 'oneMedia':
      return <OneMedia {...bottomComponent} />;
    case 'twoMedia':
      return <TwoMedia {...bottomComponent} />;
    case 'customerStories':
      return <CustomerStories {...bottomComponent} />;
    case 'bigGallery':
      return <BigGallery {...bottomComponent} />;
    case 'autostoreCardAndMedia':
      return <AutostoreCardAndMedia {...bottomComponent} />;
    case 'builderForm':
      return <ModuleBuilderForm {...bottomComponent} />;
    default:
      return;
  }
};

export default SanityModuleContent;
