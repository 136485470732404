import classNames from 'classnames';

import styles from './Steps.module.scss';

export interface StepsProps {
  numberOfSteps: number;
  activeStep: number;
  onStepClick: (step: number) => void;
}

const Steps = ({ numberOfSteps, activeStep, onStepClick }: StepsProps) => {
  return (
    <ol className={styles.steps}>
      {Array.from(Array(numberOfSteps), (e, i) => {
        return (
          <li key={i}>
            <button
              className={classNames({
                [styles.active]: activeStep === i + 1
              })}
              onClick={() => onStepClick(i + 1)}
            >
              <span>{i + 1}</span>
            </button>
          </li>
        );
      })}
    </ol>
  );
};

export default Steps;
