import { VideoContext } from './context';
import Scene from './scene';

// Useful links
// https://docs.pmnd.rs/react-three-fiber/getting-started/introduction
// https://threejs.org/docs/#api/
// https://github.com/pmndrs/drei

// Examples
// https://codesandbox.io/s/reflectorplanes-and-bloom-jflps

// https://github.com/pmndrs/gltfjsx
// > npx gltfjsx Pio_Animation.glb -tk

export const TOTAL_ANIMATION_STEPS = 4;

const AnimationApp = ({ step }: { step: number }) => {
  return (
    <VideoContext.Provider value={step}>
      <Scene />
    </VideoContext.Provider>
  );
};

export default AnimationApp;
