import { Column, Columns } from '@components/website/Columns';
import SectionWrap from '@components/website/modules/ModuleSection';
import { BREAKPOINT_TABLET } from '@constants';
import cx from 'classnames';
import { ReactElement } from 'react';
import { useMedia } from 'react-use';

import {
  CustomerStories,
  Editorial,
  LeftDigits,
  LeftMedia,
  MediumTitle,
  OneMedia,
  Quote,
  RightMedia,
  TwoMedia
} from './Components';
import styles from './styles.module.scss';

interface ModuleContentProps {
  leftColumn?: ReactElement<
    typeof MediumTitle | typeof LeftDigits | typeof LeftMedia | typeof Editorial
  >;
  rightColumn?: ReactElement<
    typeof Editorial | typeof Quote | typeof RightMedia
  >;
  bottomComponent?: ReactElement<
    typeof OneMedia | typeof TwoMedia | typeof CustomerStories
  >;
  hasGrayBg?: boolean;
  verticalPadding?: string;
}

const ModuleContent = ({
  leftColumn,
  rightColumn,
  bottomComponent,
  hasGrayBg,
  verticalPadding = 'Large'
}: ModuleContentProps) => {
  const leftAlignColumns = rightColumn?.type !== RightMedia;
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);

  return (
    <SectionWrap
      className={cx(styles.sectionWrap, styles[verticalPadding.toLowerCase()])}
      hasGrayBg={hasGrayBg}
    >
      <Columns className={styles.columnPadding}>
        {isNarrow ? (
          <>
            <Column start={1} span={4}>
              {leftColumn?.type === LeftMedia ? rightColumn : leftColumn}
            </Column>
            <Column start={6} span={6}>
              {leftColumn?.type === LeftMedia ? leftColumn : rightColumn}
            </Column>
          </>
        ) : (
          <>
            <Column start={1} span={leftAlignColumns ? 4 : 6}>
              {leftColumn}
            </Column>
            <Column
              start={leftAlignColumns ? 6 : 9}
              span={leftAlignColumns ? 6 : 4}
            >
              {rightColumn}
            </Column>
          </>
        )}
      </Columns>
      {bottomComponent && (
        <Columns className={styles.columnPadding}>
          <Column start={1} span={12}>
            {bottomComponent}
          </Column>
        </Columns>
      )}
    </SectionWrap>
  );
};

export default ModuleContent;
