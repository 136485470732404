import { UnitType } from '@grid/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface BuilderFormValues {
  width: number | null;
  height: number | null;
  length: number | null;
  numberOrders?: number | null;
  numberOrderLines?: number | null;
  unit: UnitType;
  normalizedWidth?: number;
  normalizedHeight?: number;
  normalizedLength?: number;
}

interface BuilderFormState {
  data: BuilderFormValues;
  isSubmitted: boolean;
  setIsSubmitted: (isSubmitted: boolean) => void;
  setData: (data: Partial<BuilderFormValues>) => void;
}

const useBuilderFormStore = create<
  BuilderFormState,
  [['zustand/persist', BuilderFormState]]
>(
  persist(
    set => ({
      data: {
        width: null,
        height: null,
        length: null,
        numberOrders: null,
        numberOrderLines: null,
        unit: 'm'
      },
      isSubmitted: false,
      setIsSubmitted: (isSubmitted: boolean) => set({ isSubmitted }),
      setData: newData =>
        set(state => {
          return {
            data: { ...state.data, ...newData }
          };
        })
    }),
    {
      name: 'pio-builder-form-store'
    }
  )
);

export default useBuilderFormStore;
